import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { Helmet } from 'react-helmet'
import ReactPaginate from 'react-paginate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

import Header from '../../components/Header/Header'
import Layout from '../../components/Layout/Layout'

import '../../styles/policy.css'

const BillRow = (props) => {
  const [showBillExpand, setShowBillExpand] = useState(false)
  const [expanded, setExpanded] = useState(false)

  return props.mobileWidth ? (
    <>
      <tr className="collapse-row">
        <td>
          <button onClick={() => {
            setShowBillExpand(!showBillExpand)
            setExpanded(!expanded)
          }}>
            <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
            <span className="visually-hidden">{expanded ? 'Collapse' : 'Expand'}</span>
          </button>
        </td>
        <td><a href={props.bill.link} target="_blank" rel="noreferrer">{props.bill.number}</a></td>
        <td>{props.bill.purpose}</td>
        <td>{props.bill.status}</td>
      </tr>
      <tr hidden={!showBillExpand}>
        <td colspan="4">
          <div style={{ textAlign: 'left' }}>
            <strong>Authors:</strong> {props.bill.authors.map(author => author.name)}<br />
            <strong>Committee:</strong> {props.bill.committee}<br />
            <strong>Policy Brief:</strong>
            <ReactMarkdown children={props.bill.policyBrief} />
          </div>
        </td>
      </tr>
    </>
  ) : (
    <>
      <tr className="collapse-row">
        <td>
          <button onClick={() => {
            setShowBillExpand(!showBillExpand)
            setExpanded(!expanded)
          }} disabled={!props.bill.policyBrief}><FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
            <span className="visually-hidden">{expanded ? 'Collapse' : 'Expand'}</span>
          </button>
        </td>
        <td><a href={props.bill.link} target="_blank" rel="noreferrer">{props.bill.number}</a></td>
        <td>{props.bill.authors.map(author => author.name)}</td>
        <td>{props.bill.purpose}</td>
        <td>{props.bill.committee}</td>
        <td>{props.bill.status}</td>
      </tr>
      {props.bill.policyBrief ? (
        <tr hidden={!showBillExpand}>
          <td colspan="6">
            <div style={{ textAlign: 'left' }}>
              <strong>Policy Brief:</strong>
              <ReactMarkdown children={props.bill.policyBrief} />
            </div>
          </td>
        </tr>
      ) : null}
    </>
  )
}

const LegislativeTracker = ({ data }) => {

  const pageData = data.markdownRemark.frontmatter
  const [mobileWidth, setMobileWidth] = useState()
  const housePageNumber = pageData.houseBills ? Math.ceil(pageData.houseBills.length / 10) : 0
  const senatePageNumber = pageData.senateBills ? Math.ceil(pageData.senateBills.length / 10) : 0

  const [houseBills, setHouseBills] = useState([])
  const [senateBills, setSenateBills] = useState([])

  useEffect(() => {
    if (pageData.houseBills) {
      setHouseBills(pageData.houseBills.slice(0, 10))
    }
    if (pageData.senateBills) {
      setSenateBills(pageData.senateBills.slice(0, 10))
    }

    setMobileWidth(window.matchMedia('(max-device-width: 449px)').matches)
  }, [pageData])

  let houseHandlePageClick = (data) => {
    let selected = data.selected
    let offset = Math.ceil(selected * 10)
    setHouseBills(pageData.houseBills.slice(offset, (offset + 10 > pageData.houseBills.length ? pageData.houseBills.length : offset + 10)))
  }

  let senateHandlePageClick = (data) => {
    let selected = data.selected
    let offset = Math.ceil(selected * 10)
    setSenateBills(pageData.senateBills.slice(offset, (offset + 10 > pageData.senateBills.length ? pageData.senateBills.length : offset + 10)))
  }

  return (
    <Layout title={pageData.heading}>
      <Helmet>
        <script type="text/javascript">{`
          document.querySelector('[data-tool="verify"]').classList.add('hidden')
          document.querySelector('[data-tool="register"]').classList.add('hidden')
          document.querySelector('[data-tool="locate"]').classList.add('hidden')
        `}
        </script>
      </Helmet>
      <Header background={pageData.headerImage.photo} alt={pageData.headerImage.altText}>{pageData.heading}</Header>
      <div className="main">
        {pageData.houseBills && pageData.senateBills ? (
          <>
            <h2>House</h2>
            {mobileWidth ? (
              <table>
                <thead>
                  <tr>
                    <th><span className="visually-hidden">Expand/Collapse</span></th>
                    <th>Bill No.</th>
                    <th>Purpose</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {houseBills.map(houseBill => (
                    <BillRow bill={houseBill} mobileWidth={mobileWidth} />
                  ))}
                </tbody>
              </table>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th><span className="visually-hidden">Expand/Collapse</span></th>
                    <th>Bill No.</th>
                    <th>Authors</th>
                    <th>Purpose</th>
                    <th>Committee</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {houseBills.map(houseBill => (
                    <BillRow bill={houseBill} mobileWidth={mobileWidth} />
                  ))}
                </tbody>
              </table>
            )}
            {pageData.houseBills.length > 10 ? (
              <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={housePageNumber}
                marginPagesDisplayed={1}
                pageRangeDisplayed={5}
                onPageChange={houseHandlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            ) : null}

            <h2>Senate</h2>
            {mobileWidth ? (
              <table>
                <thead>
                  <tr>
                    <th><span className="visually-hidden">Expand/Collapse</span></th>
                    <th>Bill No.</th>
                    <th>Purpose</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {senateBills.map(senateBill => (
                    <BillRow bill={senateBill} mobileWidth={mobileWidth} />
                  ))}
                </tbody>
              </table>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th><span className="visually-hidden">Expand/Collapse</span></th>
                    <th>Bill No.</th>
                    <th>Authors</th>
                    <th>Purpose</th>
                    <th>Committee</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {senateBills.map(senateBill => (
                    <BillRow bill={senateBill} mobileWidth={mobileWidth} />
                  ))}
                </tbody>
              </table>
            )}
            {pageData.senateBills.length > 10 ? (
              <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={senatePageNumber}
                marginPagesDisplayed={1}
                pageRangeDisplayed={5}
                onPageChange={senateHandlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            ) : null}
          </>
        ) : <h2>Legislative Tracker coming soon!</h2>}
      </div>
    </Layout >
  )
}

export const pageQuery = graphql`query LegislativeTrackerQuery {
  markdownRemark(frontmatter: {title: {eq: "Legislative Tracker"}}) {
    frontmatter {
      heading
      headerImage {
        photo {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        altText
      }
      houseBills {
        number
        authors {
          name
        }
        link
        purpose
        committee
        status
        policyBrief
      }
      senateBills {
        number
        authors {
          name
        }
        link
        purpose
        committee
        status
        policyBrief
      }
    }
  }
}
`

export default LegislativeTracker